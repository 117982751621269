<template>
  <div class="pageGaeRecord">
    <!--主体内容-->
    <div class="gaeRecord">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="searchWrapper"
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.park_name')">
                <el-input
                  v-model="formInline.operatorNameLike"
                  placeholder="请输入车场名称"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Audit_status')">
                <el-select
                  v-model.trim="formInline.operationType"
                  style="width: 150px"
                  placeholder="请选择审核状态"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="待审核" value="1"></el-option>
                  <el-option label="同意" value="2"></el-option>
                  <el-option label="驳回" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.processing_time')">
                <el-date-picker
                  v-model="auditTime"
                  :clearable="false"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchData"
                v-if="authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button icon="el-icon-d-arrow-left" type="info" @click="$router.go(-1)"
                >返回</el-button
              >
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column header-align="center" label="操作" width="120" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                style="padding: 0 10px 0 0"
                v-if="scope.row.operationType === 1 && authority.button.edit"
                @click="detailShow(scope.row)"
                >审核</el-button
              >
              <el-button type="text" v-if="authority.button.view" @click="detailShow2(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :title="activeItem.parkName"
      :visible.sync="dialogTableVisible"
      width="1164px"
      class="EocConnectDialog"
      :close-on-click-modal="false"
      @close="consoleDetail"
    >
      <div class="displayFlex dialogHeight">
        <div class="flex dialogLeft">
          <el-table :data="infoData" :row-style="{ height: '145px' }">
            <el-table-column
              prop="eocBerthCode"
              label="EOC系统泊位号"
              align="center"
            ></el-table-column>
            <el-table-column label="ACS修改前" align="center">
              <el-table-column prop="parkCode" :label="$t('searchModule.parking_code')" align="center"></el-table-column>
              <el-table-column prop="barCode" :label="$t('searchModule.Pole_position_number')" align="center"></el-table-column>
              <el-table-column
                prop="equipmentCode"
                label="设备编码"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="equipmentType"
                :label="$t('searchModule.Equipment_type')"
                align="center"
                :formatter="equipmentType"
              ></el-table-column>
              <el-table-column prop="berthCode" label="泊位编码" align="center"></el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex dialogRight">
          <el-table
            :data="infoDataAfter"
            :cell-class-name="afterChange"
            :row-style="{ height: '145px' }"
          >
            <el-table-column label="EOC修改后" align="center">
              <el-table-column prop="parkCode" :label="$t('searchModule.parking_code')" align="center"></el-table-column>
              <el-table-column prop="barCode" :label="$t('searchModule.Pole_position_number')" align="center"></el-table-column>
              <el-table-column
                prop="equipmentCode"
                label="设备编码"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="equipmentType"
                :label="$t('searchModule.Equipment_type')"
                align="center"
                :formatter="equipmentType"
              ></el-table-column>
              <el-table-column prop="berthCode" label="泊位编码" align="center"></el-table-column>
            </el-table-column>
            <el-table-column
              prop="berthState"
              label="有无车辆"
              align="center"
              :formatter="berthState"
            ></el-table-column>
            <el-table-column prop="operationMethod" label="操作" align="center"></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="merchant_name">
        <p>商户名称</p>
        <el-select v-model.trim="operationId" @change="selectchangeopera">
          <!-- <el-option label="全部" value=""></el-option> -->
          <el-option
            :label="value.operationName"
            :value="value.operationId"
            :key="value.operationId"
            v-for="value in tenantList"
          ></el-option>
        </el-select>
        <span style="margin-top: 12px; margin-left: 30px; margin-right: 20px">区域</span>
        <a-cascader @change="handleAreaChange" ref="cascader"></a-cascader>
        <span style="margin-top: 12px; margin-left: 30px; margin-right: 20px">品牌</span>
        <el-select v-model.trim="brandId" :disabled="!operationId">
          <!-- <el-option label="请选择" value=""></el-option> -->
          <el-option
            :label="value.brandFullName"
            :value="value.brandId"
            :key="value.brandId"
            v-for="value in brandList"
          ></el-option>
        </el-select>
      </div>

      <div class="check">
        <p style="line-height: 40px">审核意见</p>
        <el-input type="textarea" v-model="checkForm.desc"></el-input>
      </div>
      <div class="btn">
        <el-button type="primary" @click="submit(2)">确认并修改</el-button>
        <el-button type="primary" @click="submit(3)">驳回</el-button>
        <el-button @click="consoleDetail">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="activeItem.parkName"
      :visible.sync="dialogTableVisible2"
      width="80%"
      class="EocConnectDialog"
    >
      <el-table :data="infoData" max-height="400">
        <el-table-column prop="eocBerthCode" label="EOC系统泊位号" align="center"></el-table-column>
        <el-table-column prop="parkCode" :label="$t('searchModule.parking_code')" align="center"></el-table-column>
        <el-table-column prop="barCode" :label="$t('searchModule.Pole_position_number')" align="center"></el-table-column>
        <el-table-column prop="equipmentCode" label="设备编码" align="center"></el-table-column>
        <el-table-column
          prop="equipmentType"
          :label="$t('searchModule.Equipment_type')"
          align="center"
          :formatter="equipmentType"
        ></el-table-column>
        <el-table-column prop="berthCode" label="泊位编码" align="center"></el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="1"
          :page-size="infoData.length"
          layout="total, prev, pager, next, jumper"
          :total="infoData.length"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "EocConnect",
  data() {
    let date1 = new Date();
    // date1.setHours(0);
    // date1.setMinutes(0);
    // date1.setSeconds(0);
    let startTime = new Date(date1.getTime() - 3600 * 1000 * 24 * 31);
    // console.log("--startTime", startTime);
    let date = new Date();
    // date.setHours(23);
    // date.setMinutes(59);
    // date.setSeconds(59);
    let endTime = date;
    return {
      authority: "",
      checkForm: {
        desc: "",
      },
      auditTime: [], // [dateFormat(startTime, "yyyy-MM-dd"), dateFormat(endTime, "yyyy-MM-dd")],
      dialogTableVisible: false,
      dialogTableVisible2: false,
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "syncTime",
          label: "同步时间",
          width: "",
        },
        {
          prop: "auditorName",
          label: this.$t("list.Processing_personnel"),
          width: "",
        },
        {
          prop: "operationTypeName",
          label: "处理结果",
        },
        {
          prop: "auditTime",
          label: this.$t("list.processing_time"),
          width: "170",
        },
      ],
      formInline: {
        operatorNameLike: "",
        operationType: "",
      },
      // tableData: [],
      tableData: [],
      pageSize: 15,
      page: 1,
      total: 0,
      isCommit: 0,
      detailInfo: [],
      activeItem: {},
      infoDataAfter: [],
      infoData: [],
      tenantList: [],
      operationId: "",
      areaIds: "",
      brandList: [],
      brandId: "",
    };
  },
  watch: {
    dialogTableVisible(flag) {
      if (flag == false) {
        this.operationId = "";
      }
    },
  },
  methods: {
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    afterChange(row) {
      if (row.row.afterKEY.toString().indexOf(row.column.property) !== -1) {
        return "afterChange";
      } else {
        return "";
      }
    },

    berthState(r, c, v, i) {
      if (v) {
        return "有车";
      } else {
        return "无车";
      }
    },

    equipmentType(r, c, v, i) {
      // "设备类型 6：垂停 7：平行多枪 8：中位 3：枪机-半封闭"
      switch (v * 1) {
        case 3: {
          return "枪机-半封闭";
        }
        case 6: {
          return "垂停";
        }
        case 7: {
          return "平行多枪";
        }
        case 8: {
          return "中位";
        }
        case 12: {
          return "视频桩";
        }
        default: {
          return "";
        }
      }
    },

    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      this.auditTime = [];
      this.formInline.operatorNameLike = "";
      this.formInline.operationType = "";
    },
    consoleDetail() {
      this.areaIds = "";
      this.brandId = "";
      this.operationId = "";
      this.checkForm.desc = "";
      this.dialogTableVisible = false;
    },
    showLog() {
      if (this.date1 && this.date2) {
        let startTime = dateFormat(this.date1, "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(this.date2, "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime || this.date1;
        this.formInline.endTime = endTime || this.date2;
        let diff = this.date2.getTime() - this.date1.getTime();
        if (this.formInline.startTime > this.formInline.endTime) {
          this.$alert("结束时间不能小于开始时间");
          return false;
        } else if (diff > 3600 * 1000 * 24 * 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    selectchangeopera() {
      this.getBrandList();
      this.brandList = [];
      this.brandId = "";
    },
    // 品牌
    getBrandList() {
      this.$axios
        .get("/acb/2.0/brand/list", {
          data: {
            page: 0,
            pageSize: 0,
            operationId: this.operationId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.brandList = res.value.list;
          }
        });
    },
    submit(operationType) {
      if (operationType == 2 && this.isCommit == 0) {
        this.$message({
          message: "有在场车辆，不能修改",
          type: "warning",
        });
        return false;
      }
      if (operationType == 2 && !this.operationId) {
        this.$message({
          message: "请选择商户名称",
          type: "warning",
        });
        return false;
      }
      if (operationType == 2 && !this.areaIds) {
        this.$message({
          message: "请选择区域",
          type: "warning",
        });
        return false;
      }
      if (operationType == 2 && !this.brandId) {
        this.$message({
          message: "请选择品牌",
          type: "warning",
        });
        return false;
      }
      if (operationType == 3 && !this.checkForm.desc) {
        this.$message({
          message: "请填写审核意见",
          type: "warning",
        });
        return false;
      }
      this.$axios
        .post("/acb/2.0/parkEoc/updateAuditStatus", {
          data: {
            parkEocId: this.activeItem.parkEocId,
            description: this.checkForm.desc,
            operationId: this.operationId,
            operationType,
            areaId: this.areaIds,
            brandId: this.brandId,
          },
        })
        .then((res) => {
          this.$message.success("操作成功");
          this.dialogTableVisible = false;
          this.searchData();
        });
    },
    // 首页列表查询
    searchData() {
      this.$axios
        .get("/acb/2.0/parkEoc/list", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            // parentParkName: this.formInline.operatorNameLike,
            parkName: this.formInline.operatorNameLike,
            operationType: this.formInline.operationType,
            auditTimeBegin: this.auditTime[0], // this.auditTime[0] ||  todo
            auditTimeEnd: this.auditTime[1], // this.auditTime[1] ||
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.tableData = [];
            this.total = 0;
            this.$alert(res.desc);
          }
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    detailShow2(item) {
      this.activeItem = item;
      this.$axios
        .get("/acb/2.0/parkEoc/detail", {
          data: {
            parkEocId: item.parkEocId,
          },
        })
        .then((res) => {
          this.infoData = res.value.parkEocBerth;
          this.dialogTableVisible2 = true;
        });
    },
    detailShow(item) {
      this.activeItem = item;
      this.$axios
        .get("/acb/2.0/parkEoc/getById", {
          data: {
            parkEocId: item.parkEocId,
          },
        })
        .then((res) => {
          this.infoData = [];
          this.infoDataAfter = [];
          this.isCommit = res.value.commit;
          this.detailInfo = res.value.child;
          for (const detailInfoKey in this.detailInfo) {
            let detailInfoChild = this.detailInfo[detailInfoKey];
            for (const detailInfoChildKey in detailInfoChild) {
              for (const detailInfoChildObjKey in detailInfoChild[detailInfoChildKey]) {
                if (!this.infoData[detailInfoKey]) {
                  this.infoData[detailInfoKey] = {};
                }
                if (!this.infoDataAfter[detailInfoKey]) {
                  this.infoDataAfter[detailInfoKey] = {};
                }
                if (!this.infoDataAfter[detailInfoKey].afterKEY) {
                  this.infoDataAfter[detailInfoKey].afterKEY = [];
                }
                this.$set(
                  this.infoData[detailInfoKey],
                  [detailInfoChildObjKey],
                  detailInfoChild[detailInfoChildKey][detailInfoChildObjKey]
                );
                if (detailInfoChild[detailInfoChildKey].change === "Y") {
                  this.$set(
                    this.infoDataAfter[detailInfoKey],
                    [detailInfoChildObjKey],
                    detailInfoChild[detailInfoChildKey].after
                  );
                  this.infoDataAfter[detailInfoKey].afterKEY = [
                    ...new Set([
                      ...this.infoDataAfter[detailInfoKey].afterKEY,
                      detailInfoChildObjKey,
                    ]),
                  ];
                } else {
                  this.$set(
                    this.infoDataAfter[detailInfoKey],
                    [detailInfoChildObjKey],
                    detailInfoChild[detailInfoChildKey][detailInfoChildObjKey]
                  );
                }
              }
            }
          }
          console.log(this.infoData, "infoData");
          console.log(this.infoDataAfter, "infoDataAfter");
          this.dialogTableVisible = true;
          this.$nextTick(() => {
            let tableLeft = document.querySelectorAll(".dialogLeft .el-table__body")[0];
            let tableRight = document.querySelectorAll(".dialogRight .el-table__body")[0];
            console.log(tableRight, tableLeft);
            tableLeft.style.height = tableRight.offsetHeight + "px";
          });
        });
    },
  },
  components: {},
  created() {
    this.authority = this.$setAuthority("parkEocList");
    console.log("权限为==>", this.$setAuthority("parkEocList"));
  },
  mounted() {
    // 商户名称选择
    this.getTenantList();
    this.searchData();
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.EocConnectDialog >>>.afterChange .cell{
  color #ff4433
}
.dialogHeight {
  max-height 400px
  overflow-x auto
}
.displayFlex {
  display flex
}
.flex {
  flex 1
}
.pageGaeRecord {
  overflow: hidden;
  >>>.el-table{
    width 100%!important
  }
  .dialogRight >>>.el-table{
    border-left none
  }


  .pagerWrapper {
    text-align: right;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.picBox {
  padding-top: 50px;
  box-sizing: border-box;
  text-align: center;
}

.pciTile {
  text-align: center;
  margin-top: 15px;
}

.btn {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
</style>
<style lang="stylus">
.pageGaeRecord .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}
.merchant_name{
  margin-top: 20px;
  display: flex;
  >p{
    padding-top: 10px;
    margin-right: 20px;
  }
}
</style>
